import React from "react";
import {
  chakra,
  Box,
  SimpleGrid,
  Flex,
  useColorModeValue,
  Icon,
  Button,
} from "@chakra-ui/react";
import { scrollToBottom } from "./handleScroll";
export default function Features() {
  const Feature = (props) => {
    return (
      <Box>
        <Flex
          alignItems="center"
          justifyContent="center"
          w={8}
          h={8}
          mb={4}
          rounded="full"
          color={useColorModeValue(`${props.color}.600`, `${props.color}.100`)}
          bg={useColorModeValue(`${props.color}.100`, `${props.color}.600`)}
        >
          <Icon
            boxSize={5}
            viewBox="1 1 23 23"
            fill="currentColor"
            aria-hidden="true"
          >
            {props.icon}
          </Icon>
        </Flex>
        <chakra.h3
          mb={2}
          fontWeight="semibold"
          lineHeight="shorter"
          color={useColorModeValue("gray.900")}
        >
          {props.title}
        </chakra.h3>
        <chakra.p
          fontSize="sm"
          color={useColorModeValue("gray.500", "gray.400")}
        >
          {props.children}
        </chakra.p>
      </Box>
    );
  };
  return (
    <Flex
      bg={useColorModeValue("#F9FAFB", "gray.600")}
      p={20}
      w="auto"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        px={8}
        py={20}
        mx="auto"
        bg={useColorModeValue("white", "gray.800")}
        shadow="xl"
      >
        <Box textAlign={{ lg: "center" }}>
          <chakra.p
            mt={2}
            fontSize={{ base: "3xl", sm: "4xl" }}
            lineHeight="8"
            fontWeight="extrabold"
            letterSpacing="tight"
            color={useColorModeValue("gray.900")}
            as="h2"
          >
            Eksempler på hvad vi tilbyder
          </chakra.p>
          <chakra.p
            mt={4}
            maxW="2xl"
            fontSize="xl"
            mx={{ lg: "auto" }}
            color={useColorModeValue("gray.500", "gray.400")}
            as="h3"
          >
            Vi tilbyder en bred vifte af IT løsninger indenfor Design, Databehandling, Rådgivning og Optimering.
          </chakra.p>
        </Box>
        <SimpleGrid
          columns={{ base: 1, sm: 2, md: 3, lg: 4 }} 
          spacingX={{ base: 16, lg: 24 }}
          spacingY={20}
          mt={6}
        >
          <Feature
            color="pink"
            title="SEO (Search Engine Optimization) Opsætning "
            icon={
              <path d="M21.07,16.83,19,14.71a3.08,3.08,0,0,0-3.4-.57l-.9-.9a7,7,0,1,0-1.41,1.41l.89.89A3,3,0,0,0,14.71,19l2.12,2.12a3,3,0,0,0,4.24,0A3,3,0,0,0,21.07,16.83Zm-8.48-4.24a5,5,0,1,1,0-7.08A5,5,0,0,1,12.59,12.59Zm7.07,7.07a1,1,0,0,1-1.42,0l-2.12-2.12a1,1,0,0,1,0-1.42,1,1,0,0,1,1.42,0l2.12,2.12A1,1,0,0,1,19.66,19.66Z" />
            }
          >
            Gennem en korrekt SEO opsætning er du sikret at din hjemmeside bliver vist til netop den målgruppe du ønsker at ramme. Dette gør SEO til en essentiel del af at blive en synlig virksomhed og/eller brand på internettet.
          </Feature>
          <Feature
            color="red"
            title="REDCap"
            icon={
              <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm.5-13h-3a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V14h2a1,1,0,0,1,1,1v1a1,1,0,0,0,2,0V15a3,3,0,0,0-.78-2,3,3,0,0,0,.78-2V10A3,3,0,0,0,12.5,7Zm1,4a1,1,0,0,1-1,1h-2V9h2a1,1,0,0,1,1,1Z" />
            }
          >
            Med en korrekt opsat REDCap løsning kan man være sig sikret et optimalt "dataflow" til sit projekt. Vi tilbyder alt fra generel hjælp, opsætning, udsending til specialt lavet REDCap API'er.
          </Feature>
          <Feature
            color="orange"
            title="Rådgivning og Special opgaver"
            icon={
              <path d="M20.61,19.19A7,7,0,0,0,17.87,8.62,8,8,0,1,0,3.68,14.91L2.29,16.29a1,1,0,0,0-.21,1.09A1,1,0,0,0,3,18H8.69A7,7,0,0,0,15,22h6a1,1,0,0,0,.92-.62,1,1,0,0,0-.21-1.09ZM8,15a6.63,6.63,0,0,0,.08,1H5.41l.35-.34a1,1,0,0,0,0-1.42A5.93,5.93,0,0,1,4,10a6,6,0,0,1,6-6,5.94,5.94,0,0,1,5.65,4c-.22,0-.43,0-.65,0A7,7,0,0,0,8,15ZM18.54,20l.05.05H15a5,5,0,1,1,3.54-1.46,1,1,0,0,0-.3.7A1,1,0,0,0,18.54,20Z" />
            }
          >
            I tilfælde af komplekse situationer eller situationer der kræver skrædersyet løsninger tilbyder vi både rådgivning omkring samt udførelse af special opgaver.
          </Feature>
          <Feature
            color="blue"
            title="Opsætning af web løsning"
            icon={
              <path d="M10,5a1,1,0,1,0,1,1A1,1,0,0,0,10,5ZM6,5A1,1,0,1,0,7,6,1,1,0,0,0,6,5Zm8,0a1,1,0,1,0,1,1A1,1,0,0,0,14,5Zm6-4H4A3,3,0,0,0,1,4V20a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V4A3,3,0,0,0,20,1Zm1,19a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11H21ZM21,9H3V4A1,1,0,0,1,4,3H20a1,1,0,0,1,1,1Z" />
            }
          >
            Vi tilbyder opsætning af nye web løsninger eller rettelser af eksisterende web løsninger. Nye web løsninger vil i de fleste tilfælde være baseret på ReactJS.
          </Feature>
        </SimpleGrid>
        <Box textAlign={{ lg: "center" }}>
            <chakra.p
                mt={10}
                maxW="3xl"
                fontSize="xl"
                mx={{ lg: "auto" }}
                color={useColorModeValue("gray.900")}
              >
                Hos Novu Consulting tror vi på at ingen opgaver er for små, hvis behovet er stort. Derfor nægter vi at begrænse os til kun at levere prædefineret løsninger. Dette gør at vi er åbne for at levere løsninger på bestilling som er skrædersyet kundens behov.
              </chakra.p>
              <Button
                onClick={scrollToBottom}
                w="sm"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                px={5}
                py={3}
                my={4}
                border="solid transparent"
                fontWeight="bold"
                rounded="md"
                color="white"
                bg="blue.400"
                _hover={{
                  bg: "blue.200",
                }}
              >
                Kontakt os for at høre nærmere!
              </Button>
        </Box>
      </Box>
    </Flex>
  );
}