import React from 'react';
import {
  Box,
  Text,
  HStack,
  VStack,
  Heading,
} from '@chakra-ui/react';
import PriceCardComponent from './components/pricecardComponents';
import { consulting_data } from './data/pricingData';


export default function ConsultingPricing() {

return(
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
          <Heading as="h2" fontSize="4xl">
          Rådgivning og Special opgaver
          </Heading>
          <Text as="h3" fontSize="lg" color={'gray.500'}>
          Da der kan være stor forskel på projekterne er priserne kun vejledende.
          <br />
          Bemærk alle priser er eksklusiv moms.
          </Text>
      </VStack>
      <HStack
      textAlign="center"
      justify="center"
      spacing={{ base: 4, lg: 15 }}
      py={5}
      wrap="wrap"
      >
      {consulting_data.map((cons_data) => (
              <PriceCardComponent key={cons_data.priceTitle} {...cons_data} />
      ))}
      </HStack>
    </Box>
)
}