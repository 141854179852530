import React from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';
import PriceCardComponent from './components/pricecardComponents';
import { seo_data } from './data/pricingData';


export default function SeoPricing() {

return(
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
          <Heading as="h2" fontSize="4xl">
          Online markedsføring
          </Heading>
          <Text as="h3" fontSize="lg" color={'gray.500'}>
          Da der kan være stor forskel på projekterne er priserne kun vejledende.
          <br />
          Bemærk alle priser er eksklusiv moms.
          </Text>
      </VStack>
      {/* <HStack
      direction={["column","row"]}
      textAlign="center"
      justify="center"
      spacing={{ base: 4, lg: 3 }}
      py={5}
      wrap="wrap"
      >
      {seo_data.map((seo_dat) => (
              <PriceCardComponent key={seo_dat.priceTitle} {...seo_dat} />
      ))}
      </HStack> */}
      <SimpleGrid
      columns={{sm: 1, md: 2, lg: 3}}
      spacing={4}
      mx={2}
      textAlign="center"
      mt={4}
      >      
              {seo_data.map((seo_dat) => (
              <PriceCardComponent key={seo_dat.priceTitle} {...seo_dat} />
      ))}
      </SimpleGrid>
    </Box>
)
}