import React from 'react';
import {
  Box,
  Text,
  HStack,
  VStack,
  Heading,
} from '@chakra-ui/react';
import PriceCardComponent from './components/pricecardComponents';
import { web_data } from './data/pricingData';


export default function WebPricing() {

return(
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
          <Heading as="h2" fontSize="4xl">
          Web løsninger
          </Heading>
          <Text as="h3" fontSize="lg" color={'gray.500'}>
          Bemærk alle priser er eksklusiv moms.
          </Text>
      </VStack>
      <HStack
      textAlign="center"
      justify="center"
      spacing={{ base: 4, lg: 10 }}
      py={5}
      wrap="wrap"
      >
      {web_data.map((web_dat) => (
              <PriceCardComponent key={web_dat.priceTitle} {...web_dat} />
      ))}
      </HStack>
    </Box>
)
}