import {
    Box,
    Flex,
    Text,
    Button,
    Stack,
    useColorModeValue,
    useBreakpointValue,
  } from '@chakra-ui/react';

import { ColorModeSwitcher } from '.././ColorModeSwitcher';
import { scrollToBottom } from './handleScroll';
  
  export default function Nav() {
    return (
      <Box>
        <Box>
        <Flex
          bg={useColorModeValue('white', 'gray.800')}
          color={useColorModeValue('gray.600', 'white')}
          minH={'60px'}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.900')}
          align={'center'}>
          <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
            <Text
              textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
              fontFamily={'heading'}
              fontSize={22}
              color={useColorModeValue('gray.800', 'white')}
              as="h1"
              >
              <b><u>Novu Consulting</u></b>
            </Text>
          </Flex>
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
            <Button
              onClick={scrollToBottom}
              display={{ base: 'none', md: 'inline-flex' }}
              fontSize={'sm'}
              fontWeight={600}
              color={'white'}
              bg={'blue.400'}
              href={'#'}
              _hover={{
                bg: 'blue.300',
              }}>
              Kontakt os
            </Button>
            <ColorModeSwitcher justifySelf="flex-end" />
          </Stack>
        </Flex>
        </Box>
        <Box bg="blue.300">
          <Text textAlign="center"
          color={'white'}
          fontSize={18}
          >Som noget nyt tilbyder vi nu også i udvalgte situationer studierabat til flere af vores løsninger!</Text>
        </Box>
      </Box>
    );
  }
  