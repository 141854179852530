import React from 'react';
import {
  ChakraProvider,
  theme,
} from '@chakra-ui/react';
import Nav from './components/navbar';
import SplitWithImage from './components/starter';
import Features from './components/features';
import ContactUs from './components/contactUs';
import Footer from './components/footer';
import RedcapPricing from './components/pricing_modules/redcapPricing';
import SeoPricing from './components/pricing_modules/seoPricing';
import ConsultingPricing from './components/pricing_modules/consultingPricing';
import WebPricing from './components/pricing_modules/webPricing';
// import ReactGa from 'react-ga';

function App() {
  // useEffect(() => {
  //   ReactGa.initialize("UA-209005832-1")
  //   ReactGa.pageview("/")
  // }, [])

  return (
    <ChakraProvider theme={theme}>
      <Nav />
      {/* <script id="CookieDeclaration" src="https://consent.cookiebot.com/45d00e1b-2b77-4cad-8c5a-5b57af6ed686/cd.js" type="text/javascript" async></script> */}
      <SplitWithImage />
      <Features />
      <RedcapPricing />
      <SeoPricing />
      <WebPricing />
      <ConsultingPricing />
      <ContactUs />
      <Footer />
    </ChakraProvider>
  );
}

export default App;
