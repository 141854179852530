import React from 'react';
import {
  Box,
  Text,
  VStack,
  Heading,
  SimpleGrid,
} from '@chakra-ui/react';
import PriceCardComponent from './components/pricecardComponents';
import { redcap_data } from './data/pricingData';


export default function RedcapPricing() {


return(
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
          <Heading as="h2" fontSize="4xl">
          REDCap
          </Heading>
          <Text as="h3" fontSize="lg" color={'gray.500'}>
            Da der kan være stor forskel på projekterne er priserne kun vejledende.
          <br />
          Bemærk alle priser er eksklusiv moms.
          </Text>
      </VStack>
      <SimpleGrid
      columns={{sm: 1, md: 2, lg: 3}}
      spacing={4}
      mx={2}
      textAlign="center"
      mt={4}
      >     
      {redcap_data.map((red_dat) => (
              <PriceCardComponent key={red_dat.priceTitle} {...red_dat} />

      ))}

      </SimpleGrid>
    </Box>
)
}









