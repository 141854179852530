export const redcap_data = [
    {
      sub: true,
      cardSubtitle: "Fast pris 8.000 og op",
      // extra: true,
      // valutaExtra: "kr",
      // valueExtra: "1499",
      // valueLabelExtra: "Opstarts pris",
      label: "Til nye projekter",
      cardTitle: "Ny REDCap opsætning",
      valuta: "kr",
      value: "499",
      valueLabel: "/påbegyndte time",
      features: ["Opsætning af databaser i REDCap","Hjælp til udsending af spørgeskemaer","Automatisering i REDCap"],
    },
    {
      or: false,
      // extra: true,
      // valutaExtra: "kr",
      // valueExtra: "999",
      // valueLabelExtra: "Opstarts pris",
      label: "",
      cardTitle: "Opfriskning af løsning vi har skabt",
      valuta: "kr",
      value: "499",
      valueLabel: "/påbegyndte time",
      features: ["Ændringer","Rettelser","Generel opdatering"],
    },
    {
      or: false,
      // extra: true,
      // valutaExtra: "kr",
      // valueExtra: "1499",
      // valueLabelExtra: "Opstarts pris",
      label: "",
      cardTitle: "Opfriskning af løsning vi ikke har skabt",
      valuta: "kr",
      value: "499",
      valueLabel: "/påbegyndte time",
      features: ["Ændringer","Rettelser","Generel opdatering"],
    },
    {
      sub2: true,
      cardSubtitle2: "Bemærk: Uden rensning af data",
      sub: true,
      cardSubtitle: "Fast pris 2.000 og op",
      // extra: true,
      // valutaExtra: "kr",
      // valueExtra: "1499",
      // valueLabelExtra: "Opstarts pris",
      label: "",
      cardTitle: "Importering af data",
      valuta: "kr",
      value: "499",
      valueLabel: "/påbegyndte time",
      features: ["Vejledning i data struktur","Importering af data"],
    },
    {
      or: false,
      sub2: true,
      cardSubtitle2: "Prisen varierer afhængig af opgaven",
      label: "",
      cardTitle: "REDCap Specialopgaver",
      valuta: "kr",
      value: "???",
      valueLabel: "/???",
      features: ["REDCap API integrationer","Komplekse opgaver i REDCap","REDCap bestillingsopgaver"],
    },  
    {
      or: false,
      sub2: true,
      cardSubtitle2: "Prisen varierer afhængig af opgaven",
      label: "",
      cardTitle: "REDCap små justeringer",
      valuta: "kr",
      value: "???",
      valueLabel: "/???",
      features: ["Små rettelser","Små tilføjelser"],
    },
  ];
  
  export const seo_data = [
    {
      sub: true,
      cardSubtitle: "Fast pris 8.000 og op",
      // extra: true,
      // valutaExtra: "kr",
      // valueExtra: "2499",
      // valueLabelExtra: "Opstarts pris",
      label: "Til nye projekter",
      cardTitle: "SEO opsætning",
      valuta: "kr",
      value: "499",
      valueLabel: "/påbegyndte time",
      features: ["SEO opstart","Unik SEO opbygning","SEO rådgivning til fremtiden"],
    },
    {
      or: false,
      label: "",
      cardTitle: "Opfriskning af SEO løsning vi har skabt",
      valuta: "kr",
      value: "???",
      valueLabel: "/???",
      features: ["Ændringer","Rettelser","Generel opdatering"],
    },
    {
      or: false,
      label: "",
      cardTitle: "Opfriskning af SEO løsning vi ikke har skabt",
      valuta: "kr",
      value: "???",
      valueLabel: "/???",
      features: ["Ændringer","Rettelser","Generel opdatering"],
    },
    {
      or: false,
      label: "",
      cardTitle: "Google reklamer",
      valuta: "kr",
      value: "1999",
      valueLabel: "/opsætning",
      features: ["Opsætning af Google reklamer","Introduktion til styring af Google reklamer"],
    },
    {
      or: false,
      label: "",
      cardTitle: "Facebook/Instagram reklamer",
      valuta: "kr",
      value: "1999",
      valueLabel: "/opsætning",
      features: ["Opsætning af Facebook/Instagram reklamer","Introduktion til styring af Facebook/Instagram reklamer"],
    },
    {
      or: false,
      label: "",
      cardTitle: "Opfriskning af eksisterende reklamer",
      valuta: "kr",
      value: "449",
      valueLabel: "/påbegyndte time",
      features: ["Opfriskning af eksisterende reklamer vi har lavet","Opfriskning af eksisterende reklamer vi ikke har lavet","Re-introduktion til styring af reklamer"],
    },
  ];


  export const web_data = [
    {
      or: false,
      sub2: true,
      cardSubtitle2: "Prisen varierer afhængig af opgaven",
      label: "",
      cardTitle: "Web løsning",
      valuta: "kr",
      value: "???",
      valueLabel: "/???",
      features: ["Opsætning af hjemmeside","API forbindelse til ekstern API"],
    },
  ];


  
  export const consulting_data = [
    {
      or: false,
      sub2: true,
      cardSubtitle2: "Prisen kan varierer afhængig af opgaven",
      label: "",
      cardTitle: "Rådgivning",
      valuta: "kr",
      value: "499",
      valueLabel: "/påbegyndte time",
      features: ["Rådgivning til projekt struktur","Special rådgivning til større projekter"],
    },
    {
      or: false,
      sub2: true,
      cardSubtitle2: "Prisen varierer afhængig af opgaven",
      label: "",
      cardTitle: "Special opgaver",
      valuta: "kr",
      value: "???",
      valueLabel: "/???",
      features: ["Skræddersyet løsninger","Special bestillingsopgaver"],
    },
  ];



