import React from "react";
import {
    Box,
    Container,
    Stack,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react';

  
  export default function Footer() {
    return (
      <Box
        bg={useColorModeValue('gray.50', 'gray.900')}
        color={useColorModeValue('gray.700', 'gray.200')}
        borderTop="1px"
        borderRight="0px"
        borderLeft="0px"
        borderBottom="0px"
        borderColor="blue.400"
        borderWidth="thick"
        >
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          spacing={4}
          justify={'center'}
          align={'center'}>
          <Text
              fontFamily={'heading'}
              color={useColorModeValue('gray.800', 'white')}
              fontSize="xl"
              >
              <b><u>Novu Consulting</u></b>
            </Text>
        </Container>
        <Box
          borderTopWidth={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}>
          <Container
            as={Stack}
            maxW={'7xl'}
            py={4}
            direction={{ base: 'column', md: 'row' }}
            spacing={4}
            justify={{ base: 'center', md: 'space-between' }}
            align={{ base: 'center', md: 'center' }}>
            <Text>{("©"+new Date().getFullYear()+" Novu Consulting")}</Text>
            <Text href={'#'}>Adresse: Christian X's Alle 27, 2. th., 2800 Kgs. Lyngby.</Text>
            <Text href={'#'}>Mail: kontakt@novuconsulting.com</Text>
            <Text href={'#'}>Telefonnummer: +45 5020 1864</Text>
            <Text href={'#'}>CVR: 42652040</Text>
          </Container>
        </Box>
      </Box>
    );
  }