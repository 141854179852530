





function scrollToBottom() {
    window.scroll({
        top: document.body.offsetHeight,
        left: 0, 
        behavior: 'smooth',
    });
}



function scrollToRef(ref) {
    ref.current.scrollIntoView()
}

export {scrollToBottom, scrollToRef}