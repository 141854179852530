
import {
    Box,
    HStack,
    Text,
    VStack,
    useColorModeValue,
    List,
    ListItem,
    ListIcon,
    Button,
  } from '@chakra-ui/react';
  import { FaCheckCircle } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { scrollToBottom } from '../../handleScroll';
  
  function PriceWrapper({ children }) {
    return (
      <Box
        mb={4}
        shadow="base"
        borderWidth="1px"
        alignSelf={{ base: 'center', lg: 'flex-start' }}
        borderColor={useColorModeValue('gray.200', 'gray.500')}
        borderRadius={'xl'}>
        {children}
      </Box>
    );
  }

  
  
  export default function PriceCardComponent(props) {
    const history = useHistory();

    function specialClick() {
      console.log(props.specialOnClick)
      if(!props.specialOnClick) {
        scrollToBottom();
      } else {
        history.push(props.specialOnClick);
        console.log("yeeeha")
      } 
    }
    
    return (
          <PriceWrapper>
            <Box position="relative">
              <Box 
                position="absolute"
                top="-16px"
                left="50%"
                style={{ transform: 'translate(-50%)' }}>
                <Text style={{ visibility: props.label === "" ? 'hidden': 'visible'}}
                  bg={useColorModeValue('blue.300', 'blue.700')}
                  px={3}
                  py={1}
                  color={useColorModeValue('gray.900', 'gray.300')}
                  fontSize="sm"
                  fontWeight="600"
                  rounded="xl">
                  {props.label}
                </Text>
              </Box>
              <Box py={4} px={12}>
                <Text fontWeight="500" fontSize="2xl">
                  {props.cardTitle}
                </Text>
                <Text fontWeight="500" fontSize="xl" color="gray.600" style={{ visibility: props.sub2 === false ? 'hidden': 'visible'}}>
                  <u>{props.cardSubtitle2}</u>
                </Text>
                <Text fontWeight="700" fontSize="4xl" style={{ visibility: props.sub === false ? 'hidden': 'visible'}}>
                  {props.cardSubtitle}
                </Text>
                <Text fontWeight="500" fontSize="2xl" style={{ display: props.or === false ? 'none': 'visible'}}>
                  Eller
                </Text>
                <HStack justifyContent="center" style={{ visibility: props.extra === false ? 'hidden': 'visible'}}>
                  <Text fontSize="3xl" fontWeight="600">
                    {props.valutaExtra}
                  </Text>
                  <Text fontSize="5xl" fontWeight="900">
                    {props.valueExtra}
                  </Text>
                  <Text fontSize="3xl" color="gray.500">
                    {props.valueLabelExtra}
                  </Text>
                </HStack>
                <HStack justifyContent="center">
                  <Text fontSize="3xl" fontWeight="600">
                    {props.valuta}
                  </Text>
                  <Text fontSize="5xl" fontWeight="900">
                    {props.value}
                  </Text>
                  <Text fontSize="3xl" color="gray.500">
                    {props.valueLabel}
                  </Text>
                </HStack>
              </Box>
              <VStack
                bg={useColorModeValue('gray.50', 'gray.700')}
                py={4}
                borderBottomRadius={'xl'}>
                <List spacing={3} textAlign="start" px={12}>
                {props.features.map((feature) => (
                  <ListItem >
                    <ListIcon as={FaCheckCircle} color="green.500" />
                    {feature}
                  </ListItem>
                ))}
                </List>
                <Box w="80%" pt={7}>
                  <Button w="full" colorScheme="blue" 
                    onClick={specialClick}
                  >
                    {!props.button ? 'Kontakt os': props.button}
                  </Button>
                </Box>
              </VStack>
            </Box>
          </PriceWrapper>
    );
  }