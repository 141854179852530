import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Button,
    FormControl,
    Input,
    FormLabel,
    useDisclosure,
    chakra,
    Textarea,
    Divider,
    Box,
    useToast,
  } from "@chakra-ui/react";
import emailjs from 'emailjs-com';
// import ReactGa from 'react-ga';

export default function ModalPopup() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const initialRef = React.useRef()
  const toast = useToast()

  function sendEmail(e) {
    e.preventDefault() 
    
    emailjs.sendForm('service_rek4th4', 'template_fih74ee', 
      e.target, 'user_UpJm0X45uyyOLalMnsOp1')
      .then((result) => {
            if(result) {
              // ReactGa.event({
              //   category: 'Button',
              //   action: 'Contact'
              // })
              onClose()
              toast({
                title: 'Din besked blev afsendt',
                status: 'success',
                isClosable: true,
                duration: 5000,
              })
            }
        }, 
         (error) => {
            if(error) {
              toast({
                title: 'Der opstod en fejl',
                status: 'error',
                isClosable: true,
                duration: 5000,
              })
            }
         });
    }
    

  return (
    <>
      <Button onClick={onOpen}
                w="full"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                px={5}
                py={3}
                border="solid transparent"
                fontWeight="bold"
                rounded="md"
                color="white"
                bg="blue.400"
                _hover={{
                  bg: "blue.200",
                }}
              >
                Kontakt os
              </Button>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
        <Box textAlign="center">
          <ModalHeader>Kontakt os</ModalHeader>
          </Box>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <form onSubmit={sendEmail}>
            <FormControl isRequired>
              <FormLabel>Fulde navn</FormLabel>
              <Input ref={initialRef} placeholder="Fulde navn" name="name" />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Virksomhed</FormLabel>
              <Input placeholder="Virksomhed" name="company" />
            </FormControl>
            <FormControl mt={4} isRequired>
              <FormLabel>Mail</FormLabel>
              <Input placeholder="Mail" type="email" name="mail" />
            </FormControl>
          <FormControl mt={4}>
              <FormLabel>Telefonnummer</FormLabel>
              <Input placeholder="Telefonnummer" type="tel" name="phone" />
              </FormControl>
              <FormControl mt={4} isRequired>
              <FormLabel>Emne</FormLabel>
              <Input placeholder="Emne" maxLength="100" name="subject" />
              </FormControl>
              <FormControl mt={4} isRequired>
              <FormLabel>Besked</FormLabel>
              <Textarea placeholder="Besked" maxLength="2000" name="message" />
              </FormControl>
            <Box ml="125">
              <Button type="submit" colorScheme="blue" mt={2} mr={3}>
              Send
            </Button>
            <Button onClick={onClose} mt={2} >Annuller</Button>
            </Box>
            </form>
              <br />
              <Divider borderColor="blue.400" borderWidth="thin"/>  
              <br />
              <Box textAlign="center">
              <chakra.p  
              fontSize="xl"
              >Eller kontakt os direkte i stedet</chakra.p>
              </Box>
              <Box textAlign="center">
              <chakra.p><b>Mail:</b> kontakt@novuconsulting.com </chakra.p>
              <chakra.p><b>Telefonnummer:</b> +45 5020 1864 </chakra.p>
              </Box>
              </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}